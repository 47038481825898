@keyframes loadingSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loadingFirst {
    0% {
        scale: 0.8;
        fill: #DADADA;
    }

    20% {
        scale: 0.85;
        fill: #0D92D2;
    }

    40% {
        scale: 0.8;
        fill: #DADADA;
    }

    60% {
        scale: 0.8;
        fill: #DADADA;
    }

    80% {
        scale: 0.8;
        fill: #DADADA;
    }

    100% {
        scale: 0.8;
        fill: #DADADA;
    }
}

@keyframes loadingSecond {
    0% {
        scale: 0.8;
        fill: #DADADA;
    }

    20% {
        scale: 0.8;
        fill: #DADADA;
    }

    40% {
        scale: 0.85;
        fill: #0D92D2;
    }

    60% {
        scale: 0.8;
        fill: #DADADA;
    }

    80% {
        scale: 0.8;
        fill: #DADADA;
    }

    100% {
        scale: 0.8;
        fill: #DADADA;
    }
}

@keyframes loadingThird {
    0% {
        scale: 0.8;
        fill: #DADADA;
    }

    20% {
        scale: 0.8;
        fill: #DADADA;
    }

    40% {
        scale: 0.8;
        fill: #DADADA;
    }

    60% {
        scale: 0.85;
        fill: #0D92D2;
    }

    80% {
        scale: 0.8;
        fill: #DADADA;
    }

    100% {
        scale: 0.8;
        fill: #DADADA;
    }
}

@keyframes loadingFourth {
    0% {
        scale: 0.8;
        fill: #DADADA;
    }

    20% {
        scale: 0.8;
        fill: #DADADA;
    }

    40% {
        scale: 0.8;
        fill: #DADADA;
    }

    60% {
        scale: 0.8;
        fill: #DADADA;
    }

    80% {
        scale: 0.85;
        fill: #0D92D2;
    }

    100% {
        scale: 0.8;
        fill: #DADADA;
    }
}