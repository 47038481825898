::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #9090901c;
    border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #86868647;
    border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #86868675;
}

.styleTooltip{
    padding: 10px !important;
    background-color: #053952 !important;
    font-size: 16px !important;
}